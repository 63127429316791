import React, { useEffect, useState } from 'react'
import './styles.scss'
import { getTickets } from '../../api';
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { Badge } from 'react-bootstrap';



const TicketsProgressFields = () => {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [ticket, setTicket] = useState([]);

    useEffect(
        () => {
            getTickets(id).then(async res => {
                setTicket(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])

    return (
        <div className='ticketProgressWrapper'>
            <div className='mb-4'>
                <h4 className='ticketProgressTitle'>My Tickets</h4>
            </div>
            <div className='ticketProgressContainer'>

                <div className='ticketProgressCardContainer'>
                    {
                        ticket.map(ticket => (
                            <div className={ticket.status === 0 ? 'ticketProgressCard ticketProgressCardPending' : 'ticketProgressCard ticketProgressCardSuccess'}>
                                <p>Id: #{ticket?.generatedNumber}</p>
                                <p>Date: {moment(ticket.createdAt).format('L')}</p>
                                <div>
                                    Status: {ticket.status === 0 ? <Badge bg="warning" text="dark"> Warning</Badge> : <Badge bg="success">Completed</Badge>}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default TicketsProgressFields