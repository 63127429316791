import React from 'react'
import './styles.scss'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from "react-router-dom"


const UserDropdown = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();


    const logOutUserMenu = () => {
        cookie.remove('token');
        navigate("/");
    }

    return (
        <DropdownButton id="dropdown-basic-button" title="Settings">
            <Dropdown.Item>
                <Link to="/profile" className='navbarLink'>
                    My Profile
                </Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => logOutUserMenu()}>LogOut</Dropdown.Item>
        </DropdownButton>
    )
}

export default UserDropdown