import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.scss'

const FloatingListSection = ({ data, margin, title }) => {
    return (
        <div className='floatingWrapper' data-aos="fade-up" data-aos-duration="2000" style={{ margin: `${margin}` }}>

            <Container style={{ padding: 0 }}>

                <>
                    <h2 className='floatingSectionTitle'>{title}</h2>
                </>

                <div className='floatingContainer'>

                    {
                        data?.map(data => (
                            <div className={`${data.className} floatingDataContainer`} key={data.id}>
                                <div className='floatingDataIcon'>
                                    {data.icon}
                                </div>
                                <div className='floatingTitleContainer'>
                                    <p>{data.title}</p>
                                </div>
                                <div className='floatingDescriptionContainer'>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </div>
    )
}

export default FloatingListSection