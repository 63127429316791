import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.scss'
import { floatingData, services } from '../../../data'
import { BiCheckCircle } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import FloatingListSection from '../../global/floatingsection'

const AboutUsServices = () => {
    return (
        <div className='aboutusServiceWrapper'>
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceTitle' data-aos="fade-up" data-aos-duration="500">
                            EXPERT ASSISTANCE IN CRYPTO RECOVERY
                        </p>
                    </Col>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceSubTitle' data-aos="fade-up" data-aos-duration="1000">
                            Regained access to your cryptocurrency holdings. Our team of experienced professionals is dedicated to
                            recovering a wide range of lost and inaccessible cryptocurrencies.
                            Here are common scenarios where our services prove invaluable:
                        </p>
                    </Col>
                </Row>
                <Row>
                    <div className='aboutusServicesContainer'>
                        {
                            services.map((item, index) => (
                                <Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
                                    <div key={index} data-aos="fade-up" data-aos-duration="1500" className='aboutusService'>
                                        <Link to='/services' style={{ textDecoration: 'none' }}>
                                            <p>{item.name}</p>
                                        </Link>
                                    </div>
                                </Col>
                            ))
                        }
                    </div>
                </Row >
            </Container >
        </div >
    )
}

export default AboutUsServices