import React from 'react'
import './styles.scss'
import { Badge, Col, Row } from 'react-bootstrap';


const QA = ({ title, data }) => {

    return (
        <div className='qAWrapper'>
            <div className='qATitleContainer'>
                <p>{title}</p>
            </div>

            <div className='qAContainer'>
                <Row>
                    {
                        data?.map(data => (
                            <Col xxl={data?.size} xl={data?.size} lg={data?.size} md={12} sm={12} xs={12} style={{ padding: 0 }}>
                                <div className='qaCardContainer' style={{ height: `${data?.height}` }} data-aos="fade-up" data-aos-duration="1000">
                                    <div className='qaCardQuestionContainer'>
                                        <Badge className='qaCardQuestionBadge'>Q</Badge>
                                        <h5 className='qaCardQuestion'>{data?.question}</h5>
                                    </div>
                                    <div className='qaCardAnswerContainer'>
                                        <Badge className='qaCardAnswerBadge'>A</Badge>
                                        <p className='qaCardAnswer'>
                                            {data?.answer}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>
    )
}

export default QA