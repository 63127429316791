import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Cookies from 'universal-cookie';
import { Link, useLocation } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './styles.scss'
import { Button, Nav } from 'react-bootstrap';
import { links, loggedInLinks } from '../../../data';
import UserDropdown from './dropdown';
import logo from '../../../assets/images/allcryptorefund.png'

const NavBar = () => {
    const cookie = new Cookies();

    const location = useLocation();

    return (
        <Navbar className='navbar' expand="lg">
            <Container>
                <Navbar.Brand href="/" style={{ color: 'white' }}>
                    <img src={logo} width={72} height={51} className='navbarLogo' alt='logo' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='offcanvasNavbar-expand-lg' />
                <Navbar.Offcanvas
                    className="offcanvasNavbarContainer"
                    id='offcanvasNavbar-expand-lg'
                    aria-labelledby='offcanvasNavbarLabel-expand-lg'
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id='offcanvasNavbarLabel-expand-lg' className='offCanvasTitle'>
                            Menu
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">

                            {
                                links.map(link => (
                                    <Navbar.Text key={link.link}>
                                        <Link to={link.path} className={location.pathname === link.path ? "active" : 'navbarLinks'}>
                                            <p>{link.link}</p>
                                        </Link>
                                    </Navbar.Text>
                                ))
                            }

                            {

                                cookie.get('token') ?
                                    <>
                                        {
                                            loggedInLinks.map(link => (
                                                <Navbar.Text key={link.link}>
                                                    <Link to={link.path} className={location.pathname === link.path ? "active" : 'navbarLinks'}>
                                                        <p>{link.link}</p>
                                                    </Link>
                                                </Navbar.Text>
                                            ))
                                        }
                                        <UserDropdown />

                                    </>
                                    :
                                    <>
                                        <Link to='/signin'>
                                            <Button
                                                className='navbarSignInButton'
                                            >
                                                Sign In
                                            </Button>
                                        </Link>
                                        <Link to='/signup'>
                                            <Button
                                                className='navbarSingUpButton'
                                            >
                                                Sign Up
                                            </Button>
                                        </Link>
                                    </>

                            }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar >
    )
}

export default NavBar