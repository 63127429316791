import React, { useState } from 'react'
import './styles.scss'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { login } from '../../api';


const SignInFields = () => {
    const [message, setMessage] = useState()
    const cookie = new Cookies()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;

            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('lastname', response.data.lastName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        navigate('/');
                    } else {
                        setMessage('user not found')
                    }
                })
            }

        }
    })


    return (
        <div className='signInWrapper'>

            <Container>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={12} xl={12} lg={12}>
                        <h4 className='signinLabel mb-4'>Sign In</h4>
                    </Col>
                </Row>
                <Form onSubmit={formik.handleSubmit}>
                    <Row xxl={12} xl={12} lg={12}>
                        <p className='signInMessage'>
                            {message}
                        </p>
                        <Col xxl={12} xl={12} lg={12}>
                            <Form.Group className="mb-4" controlId="username">
                                <Form.Label
                                    className='inputLabel'
                                >
                                    Username
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.username || ''}
                                    name="username"
                                    id="username"
                                    className="input"
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={12} xl={12} lg={12}>
                            <Form.Group className="mb-5" controlId="password">
                                <Form.Label
                                    className='inputLabel'

                                >
                                    Password
                                </Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password || ''}
                                    name="password"
                                    id="password"
                                    className="input"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row >
                        <Button type="submit" className='signInSubmitButton mb-3'>
                            Sign In
                        </Button>
                    </Row>
                </Form>

            </Container>
        </div>
    )
}

export default SignInFields