import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingListSection from '../../components/global/floatingsection'
import { floatingData } from '../../data'
import RecoveryService from '../../components/homepage/recoveryservice'
import Testimonials from '../../components/global/testimonials'

const HomePage = () => {

    return (
        <Fragment>

            <Introduction
                height={90.3}
                title1="NO ADVANCE PAYMENT REQUIRED FOR FUND RECOVERY."
                mainTitle="Regain Control Of Your Lost Funds."
                title2="Recover your lost cryptocurrency with us, without any upfront payment required. Our team of experts is committed to helping you regain access to your valuable assets and providing professional assistance throughout the recovery process."
            />

            <FloatingListSection
                title='Some Of Our Services'
                margin='64px 0px'
                data={floatingData}
            />

            <RecoveryService />

            <Testimonials
                title='HEAR FROM OUR SATISFIED CUSTOMERS'
                subTitle='Discover the feedback from our clients.'
            />

        </Fragment>
    )
}

export default HomePage