import React from 'react'
import './styles.scss'
import { Button, Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FloatingListSection from '../../global/floatingsection'
import { floatingData2 } from '../../../data'
import serviceImage from '../../../assets/images/bitcoin.jpg'

const RecoveryService = () => {
    return (
        <div className='recoveryServiceWrapper'>
            <Container>
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className='introductionImageContainer' data-aos="fade-up" data-aos-duration="2000">
                            <img src={serviceImage} alt='serviceImage' />
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12} className='recoveryServiceTitlesContainer'>
                        <div className='recoveryServiceTitleTwo' data-aos="fade-up" data-aos-duration="1000">
                            <p>PROFESSIONAL ASSISTANCE FOR RECOVERING CRYPTO ASSETS</p>
                        </div>
                        <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                            <p style={{ color: 'white' }}>
                                Utilize our expertise in recovering lost funds, providing relief to numerous victims worldwide
                                by helping them reclaim stolen and inaccessible cryptocurrencies. Let us alleviate your distress,
                                sparing you from the anguish of endless days and sleepless nights as you strive to regain control
                                over your wealth.
                            </p>
                        </div>
                        <div className='recoverySubTitle' data-aos="fade-up" data-aos-duration="2000">
                            <p>ACHIEVING SUCCESS THROUGH EXTENSIVE EXPERIENCE</p>
                        </div>
                        <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                            <p style={{ color: 'white' }}>
                                Collaborating with renowned leaders in the industry, experts, and state-of-the-art blockchain
                                forensic tools, we combine well-established blockchain analytics and investigative methodologies
                                to efficiently recover your funds.
                            </p>
                        </div>

                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default RecoveryService