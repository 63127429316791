import React, { useState } from 'react'
import './styles.scss'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { register } from '../../api';



const SignUpFields = () => {

    const [errormessage, setErrorMessage] = useState('')
    const [phoneValue, setPhoneValue] = useState()


    const validationSchema = Yup.object({
        firstname: Yup
            .string('Enter your first name')
            .required('First name is required'),
        lastname: Yup
            .string('Enter your last name')
            .required('Last name is required'),
        username: Yup
            .string('Enter your User name')
            .required('Username is required'),
        password: Yup
            .string('Enter your password')
            .required('Password is required'),
        email: Yup
            .string('Enter your email')
            .required('Email is required'),
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                phone: phoneValue,
                username: formik.values.username,
                password: formik.values.password,
            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage('Account successfully created !')
                    window.location.reload()
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })


    return (
        <div className='signupFieldsWrapper'>
            <Container>
                <Form onSubmit={formik.handleSubmit}>
                    <Row xxl={12} xl={12} lg={12}>
                        <Col xxl={12} xl={12} lg={12}>
                            <h4 className='signupLabel mb-4'>Sign Up</h4>
                        </Col>
                    </Row>
                    <Row xxl={12} xl={12} lg={12}>
                        {
                            errormessage ?
                                <p className='errorMessage'>{errormessage}</p>
                                :
                                null
                        }
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label className='inputLabel'>First Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.firstname || ''}
                                    name="firstname"
                                    id="firstname"
                                    className='input'
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicLastName">
                                <Form.Label className='inputLabel'>Last Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.lastname || ''}
                                    name="lastname"
                                    id="lastname"
                                    className='input'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row xxl={12} xl={12} lg={12}>
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-3" controlId="username">
                                <Form.Label className='inputLabel'>Username</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.username || ''}
                                    name="username"
                                    id="username"
                                    className='input'
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label className='inputLabel'>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password || ''}
                                    name="password"
                                    id="password"
                                    className='input'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row xxl={12} xl={12} lg={12}>
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-5" controlId="email">
                                <Form.Label className='inputLabel'>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email || ''}
                                    name="email"
                                    id="email"
                                    className='input'
                                />
                            </Form.Group>
                        </Col>
                        <Col xxl={6} xl={6} lg={6}>
                            <Form.Group className="mb-5" controlId="formBasicPhone" >
                                <Form.Label className='inputLabel'>Phone Number</Form.Label>
                                <PhoneInput
                                    required
                                    defaultCountry="US"
                                    className='phoneNumberInput'
                                    value={phoneValue}
                                    onChange={setPhoneValue}
                                    name="phone"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Button type="submit" className='signUpSubmitButton'>
                            Sign up
                        </Button>
                    </Row>
                </Form>
            </Container>
        </div >
    )
}

export default SignUpFields