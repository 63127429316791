import React, { Fragment } from 'react'
import './styles.scss'
import { Col, Container, Row } from 'react-bootstrap';
import serviceImage from '../../../assets/images/bitcoin.jpg'

const Introduction = ({
    title1,
    mainTitle,
    title2
}) => {


    return (
        <Fragment>
            <div className='introductionWrapper'>
                <Container
                    className='introductionContainer'
                >
                    <Row>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div className='introductionTitlesContainer'>
                                <div className='introductionFirstTitle' data-aos="fade-up" data-aos-duration="500">
                                    <p>{title1}</p>
                                </div>
                                <div className='introductionMainTitle' data-aos="fade-up" data-aos-duration="1000">
                                    <p>{mainTitle}</p>
                                </div>
                                <div className='introductionSecondTitle' data-aos="fade-up" data-aos-duration="1500">
                                    <p>{title2}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className='introductionImageContainer' data-aos="fade-up" data-aos-duration="2000">
                                <img src={serviceImage} alt='serviceImage' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default Introduction