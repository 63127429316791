import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'
import { testimonials } from '../../../data'
import Rating from '../rating'
import { shuffleArray } from '../../../hooks/shuffleArray';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Keyboard, Autoplay } from "swiper";




const Testimonials = ({ title, subTitle }) => {
    return (
        <div className='testimonialWrapper'>
            <Container>
                <div className='testimonialTitleContainer' data-aos="fade-up" data-aos-duration="500">
                    <p>{title}</p>
                </div>
                <div className='testimonialSubTitleContainer' data-aos="fade-up" data-aos-duration="1000">
                    <p>{subTitle}</p>
                </div>

                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    loop={true}
                    keyboard={{
                        enabled: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    modules={[EffectCoverflow, Pagination, Keyboard, Autoplay]}
                    className="mySwiper"
                    data-aos="fade-up" data-aos-duration="1000"
                >
                    {
                        shuffleArray(testimonials).map(testimonial => (
                            <>
                                <SwiperSlide>
                                    <div className='testimonialContainer' key={testimonial.id}>
                                        <div style={{ display: 'flex' }}>
                                            <div className='testimonialAvatar'>
                                                <img src={testimonial.avatar} width={70} height={70} alt='testimonialAvatar' />
                                            </div>
                                            <div className='ratingNameContainer'>
                                                <div className="nameContainer">
                                                    <p>{testimonial.name}</p>
                                                </div>
                                                <div className='ratingContainer'>
                                                    <Rating rating={testimonial.rating} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='testimonialBodyContainer'>
                                            <p>{testimonial.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide >
                            </>
                        ))
                    }
                </Swiper>
            </Container>
        </div >
    )
}

export default Testimonials