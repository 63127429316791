import React from 'react'
import './styles.scss'
import { services } from '../../../data'
import { Container } from 'react-bootstrap'

const ServicesCards = () => {
    return (
        <div className='serviceWrapper'>
            <div className='serviceTitle' data-aos="fade-up" data-aos-duration="500">
                <p>Main Services</p>
            </div>
            <Container>
                <div class="serviceContainer">
                    <div class="serviceCards">
                        {
                            services?.map(service => (
                                <div class="serviceCard card-one">
                                    <h3 class="serviceCardTitle">{service?.name}</h3>
                                    <p class="serviceDescription">{service?.description}</p>
                                </div>
                            ))
                        }


                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ServicesCards