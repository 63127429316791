import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { floatingData, floatingData2 } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import AboutUsServices from '../../components/aboutus/services'
import './styles.scss'
import Testimonials from '../../components/global/testimonials'

const AboutUs = () => {
    return (
        <Fragment>
            <Introduction
                height={100}
                title1="WHAT SETS US APART?"
                mainTitle="OUR DISTINCT ADVANTAGE"
                title2="With a proven track record of recovering lost funds, our team brings unparalleled experience and expertise to the table. Join our growing list of satisfied clients who have successfully reclaimed millions of dollars with our assistance."
                backgroundImage={background}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <AboutUsServices />

            <FloatingListSection
                title="Services"
                margin='64px 0px'
                data={floatingData}
            />

            <Introduction
                height={50}
                title1="EXPERTS IN CRYPTO FUND RECOVERY"
                mainTitle="Restoring Lost Cryptocurrency"
                title2="Our expertise lies in restoring lost cryptocurrency, ensuring you have the highest chance of recovering your funds."
                backgroundImage='none'
                mobileBackgroundImage='none'
                backgroundColor='black'
                mobileBackgroundColor='black'
            />

            <Testimonials
                title='WE’VE HELPED MANY PEOPLE'
                subTitle='RECOVER THEIR LOST FUNDS'
            />
        </Fragment>
    )
}

export default AboutUs