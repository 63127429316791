import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { serviceHighlight } from '../../data'
import ServicesCards from '../../components/services/servicesCard'
import ServiceHighlight from '../../components/services/serviceHighlight'
import QA from '../../components/global/accordion'

const Services = () => {
    return (
        <Fragment>

            <Introduction
                title1="A TRUSTED HISTORY OF RESULTS"
                mainTitle="RELIABLE SUCCESS RATE"
                title2="Our consistent success in recovering funds has earned the trust of countless victims of scams worldwide, leading to the restoration of millions of dollars in stolen funds."
            />

            <ServicesCards />

            <ServiceHighlight />

            <QA
                title='Q&A'
                data={serviceHighlight}
            />
        </Fragment>
    )
}

export default Services