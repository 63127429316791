import {
    FaBitcoin,
    FaIdCard,
    FaUserLock,
    FaMask,
    FaExchangeAlt,
    FaShieldVirus
} from "react-icons/fa";
import { TfiReload } from 'react-icons/tfi'
import { HiMailOpen } from 'react-icons/hi'
import { MdOutlineSyncProblem, MdOutlineVisibilityOff } from "react-icons/md";
import { BsCoin, BsFillClipboardDataFill, BsInstagram, BsFacebook, BsTwitter } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlineCloudDownload } from 'react-icons/ai';
import { FiHardDrive } from 'react-icons/fi';
import { GiBreakingChain } from 'react-icons/gi';

import avatar1 from './assets/images/avatar1.jpg'
import avatar2 from './assets/images/avatar2.jpg'
import avatar3 from './assets/images/avatar3.jpg'
import avatar4 from './assets/images/avatar4.jpg'
import avatar5 from './assets/images/avatar5.jpg'
import avatar6 from './assets/images/avatar6.jpg'
import avatar7 from './assets/images/avatar7.jpg'
import avatar8 from './assets/images/avatar8.jpg'
import avatar9 from './assets/images/avatar9.jpg'
import avatar10 from './assets/images/avatar10.jpg'
import avatar11 from './assets/images/avatar11.jpg'
import avatar12 from './assets/images/avatar12.jpg'
import users from './assets/images/users.png'
import headphones from './assets/images/headphones.png'
import speed from './assets/images/speed.png'
import handshake from './assets/images/handshake.png'
import doctor from './assets/images/doctor.png'


export const links = [
    {
        link: 'Home',
        path: '/',
    },
    {
        link: 'About Us',
        path: '/aboutus',
    },
    {
        link: 'Services',
        path: '/services',
    },
    {
        link: 'Q&A',
        path: '/more',
    },
]

export const loggedInLinks = [
    // {
    //     link: 'Profile',
    //     path: '/profile'
    // },
    {
        link: 'My Ticket',
        path: '/ticket'
    },
    {
        link: 'Ticket Progress',
        path: '/ticket-progress'
    },
]

export const floatingData = [
    {
        id: '7',
        title: 'Data Privacy',
        description: 'Protect your personal and financial information from unauthorized access and data breaches.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'DarkTheme',
    },
    {
        id: '8',
        title: '24/7 Monitoring',
        description: 'Continuous monitoring of your online accounts and transactions to detect any suspicious activities.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme',
    },
    {
        id: '9',
        title: 'Education and Awareness',
        description: 'Stay informed about the latest online scams and fraud techniques to prevent falling victim to them.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'DarkTheme',
    },
    {
        id: '10',
        title: 'Legal Assistance',
        description: 'Get professional legal guidance and representation for cases related to financial fraud and cybercrime.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme',
    },
    {
        id: '11',
        title: 'Credit Monitoring',
        description: 'Monitor your credit reports and scores to ensure accuracy and detect any unauthorized activities.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'DarkTheme',
    },
    {
        id: '12',
        title: 'Fraud Detection',
        description: 'Utilize advanced algorithms and machine learning to identify and prevent fraudulent transactions.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme',
    },
    {
        id: '13',
        title: 'Secure Online Payments',
        description: 'Enjoy safe and secure online transactions with robust encryption and secure payment gateways.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'DarkTheme',
    },
    {
        id: '14',
        title: 'Cybersecurity Consulting',
        description: 'Receive expert advice and recommendations to enhance your online security infrastructure.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme',
    },
    {
        id: '15',
        title: 'Identity Theft Protection',
        description: 'Safeguard your identity from theft and fraudulent use through proactive monitoring and alert systems.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'DarkTheme',
    },
];

export const floatingData2 = [
    {
        id: '1',
        title: 'Seek Assistance',
        answer: 'Engage a skilled Recovery Consultant to assess your situation.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme'
    },
    {
        id: '2',
        title: 'Construct Your Case',
        answer: 'Gather the essential evidence and devise a winning strategy.',
        icon: <img src={doctor} alt="doctor" />,
        className: 'greenTheme'
    },
    {
        id: '3',
        title: 'Reclaim Your Assets',
        answer: 'With the appropriate solution, let us retrieve your funds promptly!',
        icon: <FaBitcoin size={70} className='lightTheme' />,
        className: 'lightTheme'
    },

]

export const testimonials = [
    {
        id: 25,
        name: "Thomas Anderson",
        description: "Crypto Recovery is the best in the business when it comes to recovering lost crypto funds. They provided exceptional service and exceeded my expectations.",
        rating: '5',
        avatar: avatar2,
    },
    {
        id: 26,
        name: "Emma Walker",
        description: "I couldn't be happier with the outcome of working with Crypto Recovery. They successfully recovered my lost crypto assets with utmost professionalism.",
        rating: '5',
        avatar: avatar1,
    },
    {
        id: 27,
        name: "Christopher Harris",
        description: "Crypto Recovery's team of experts went above and beyond to recover my inaccessible crypto funds. Their knowledge and skills are outstanding.",
        rating: '5',
        avatar: avatar4,
    },
    {
        id: 28,
        name: "Aria Thompson",
        description: "I highly recommend Crypto Recovery for their exceptional service and commitment to their clients. They truly understand the importance of crypto asset recovery.",
        rating: '5',
        avatar: avatar3,
    },
    {
        id: 29,
        name: "Maxwell White",
        description: "Crypto Recovery is the go-to solution for anyone facing crypto asset loss. Their expertise and dedication are unparalleled.",
        rating: '5',
        avatar: avatar6,
    },
    {
        id: 30,
        name: "Madison Turner",
        description: "I am grateful to Crypto Recovery for helping me regain access to my locked crypto wallet. Their professionalism and support made a significant difference.",
        rating: '5',
        avatar: avatar5,
    },
    {
        id: 31,
        name: "William Green",
        description: "Crypto Recovery is a trustworthy partner for recovering lost crypto assets. Their team has the necessary skills and knowledge to tackle any challenge.",
        rating: '5',
        avatar: avatar8,
    },
    {
        id: 32,
        name: "Chloe Adams",
        description: "I had almost given up hope on recovering my lost crypto funds, but Crypto Recovery proved me wrong. They are truly experts in their field.",
        rating: '5',
        avatar: avatar7,
    },
    {
        id: 33,
        name: "Alexander Moore",
        description: "Crypto Recovery's dedication and professionalism are commendable. They successfully recovered my inaccessible crypto assets and provided excellent support throughout the process.",
        rating: '5',
        avatar: avatar10,
    },
    {
        id: 34,
        name: "Scarlett Phillips",
        description: "I cannot thank Crypto Recovery enough for their outstanding service. They truly care about their clients and work tirelessly to recover lost crypto funds.",
        rating: '5',
        avatar: avatar9,
    },
    {
        id: 35,
        name: "Henry Carter",
        description: "Crypto Recovery is the top choice for anyone in need of crypto asset recovery. Their expertise and dedication set them apart from the rest.",
        rating: '5',
        avatar: avatar12,
    },
    {
        id: 36,
        name: "Victoria Lee",
        description: "I had a great experience with Crypto Recovery. They successfully recovered my lost crypto assets and provided excellent customer support throughout the process.",
        rating: '5',
        avatar: avatar11,
    }
];

export const services = [
    {
        name: 'Asset Misplacement',
        icon: <FaIdCard size={80} color="#fff" />,
        description: 'Losing track of your digital assets and needing assistance in locating and accessing them.',
    },
    {
        name: 'Transaction Errors',
        icon: <TfiReload size={80} color="#fff" />,
        description: 'Encountering errors or issues during blockchain transactions that require professional intervention.',
    },
    {
        name: 'Unauthorized Account Access',
        icon: <FaMask size={80} color="#fff" />,
        description: 'Dealing with unauthorized access to your cryptocurrency accounts and the need for recovery solutions.',
    },
    {
        name: 'Cyberbullying and Extortion',
        icon: <HiMailOpen size={80} color="#fff" />,
        description: 'Addressing instances of cyberbullying and extortion related to your crypto holdings and seeking resolution.',
    },
    {
        name: 'Key and Password Recovery',
        icon: <FaUserLock size={80} color="#fff" />,
        description: 'Recovering lost or forgotten passwords or keys necessary to access your cryptocurrency assets.',
    },
    {
        name: 'Forked Coin Retrieval',
        icon: <BsCoin size={80} color="#fff" />,
        description: 'Assisting in the retrieval of coins resulting from blockchain forks or airdrop events.',
    },
    {
        name: 'Exchange Security Breach',
        icon: <FaExchangeAlt size={80} color="#fff" />,
        description: 'Recovering funds impacted by security breaches or hacking incidents on cryptocurrency exchanges.',
    },
    {
        name: 'Ransomware Recovery',
        icon: <AiOutlineWarning size={80} color="#fff" />,
        description: 'Recovering crypto assets that have been compromised or held hostage due to ransomware attacks.',
    },
    {
        name: 'Data Storage Failure',
        icon: <FiHardDrive size={80} color="#fff" />,
        description: 'Recovering cryptocurrencies from failed or inaccessible data storage devices or hard drives.',
    },
    {
        name: 'Virus Protection',
        icon: <FaShieldVirus size={80} color="#fff" />,
        description: 'Addressing the impact of viruses and malware on your cryptocurrency assets and implementing effective protection measures.',
    },
    {
        name: 'Software Compatibility Issues',
        icon: <MdOutlineSyncProblem size={80} color="#fff" />,
        description: 'Resolving software glitches and compatibility issues affecting your crypto investments.',
    },
    {
        name: 'Compromised Data Recovery',
        icon: <BsFillClipboardDataFill size={80} color="#fff" />,
        description: 'Recovering funds compromised in data breaches or security compromises, ensuring the safety of your assets.',
    },
]

export const highlights = [
    {
        icon: <AiOutlineCloudDownload size={70} color="#0068FF" />,
        title: 'RECOVER LOST FUNDS',
        description: 'Regain access to stolen or lost cryptocurrency funds through our specialized expertise and proven recovery methods. We have successfully assisted numerous scam victims worldwide in reclaiming millions of dollars. Our investigations involve tracking crypto movements, identifying culprits.'
    },
    {
        icon: <GiBreakingChain size={70} color="#0068FF" />,
        title: 'COMPREHENSIVE BLOCKCHAIN ANALYSIS',
        description: 'Leveraging cutting-edge blockchain forensics tools and techniques, we offer in-depth investigation services. We collaborate with exchanges and law enforcement agencies, providing expert witness testimony. Our primary objective is to trace and recover your funds.'
    },
    {
        icon: <MdOutlineVisibilityOff size={70} color="#0068FF" />,
        title: 'UNCOVER CONCEALED CRYPTOCURRENCY',
        description: 'Expose hidden cryptocurrency assets in cases involving divorce, embezzlement, bankruptcy, financial fraud, and tax evasion. Our advanced tracking and auditing capabilities trace crypto movements, determine ownership, and provide comprehensive forensic reports, declarations, and expert witnesses.'
    },
]

export const moreData = [
    {
        id: '0',
        question: "FALL VICTIM TO CRYPTO TRADING FRAUD?",
        size: 6,
        height: '300px',
        answer: 'According to a survey by securities regulators NASAA, crypto scams were the most prevalent form of cryptocurrency-based crime in 2021, resulting in billions of dollars lost. Scammers often promise guaranteed high returns with no risk, targeting unsuspecting investors. If you have been scammed and lost cryptocurrency, Crypto Recovery can help you recover your funds. We are a trusted crypto recovery service that has successfully assisted numerous scam victims from around the world in reclaiming their stolen funds.',
    },
    {
        id: '1',
        question: "BEWARE OF 'CRYPTO RECOVERY SERVICE' FRAUDS",
        size: 6,
        height: '300px',
        answer: 'If you have fallen victim to fraud and lost your savings or retirement funds to a cryptocurrency scam, be cautious of offers promising to recover your lost funds. Recovery scams are a type of advance-fee fraud where victims are asked to pay upfront for the chance of receiving a much larger sum later. These scams target individuals who have already been harmed by other frauds. At Crypto Recovery, we are a trustworthy crypto recovery service that knows how to help you recover your funds. We have assisted hundreds of scam victims worldwide in recovering millions of dollars of stolen funds.',
    },
    {
        id: '2',
        question: "FORGOT OR MISPLACED YOUR PASSWORD?",
        size: 7,
        height: '300px',
        answer: 'It is estimated that billions worth of Bitcoin have been locked by users who have forgotten or lost their access keys. Losing your private key means permanently losing access to your wallet and the funds it contains. Many people have been unable to cash in on their Bitcoin fortunes due to forgotten keys. If you have forgotten or misplaced your password, Crypto Recovery engineers can help.',
    },
    {
        id: '3',
        question: "ACCIDENTALLY DELETED YOUR WALLET?",
        size: 5,
        height: '300px',
        answer: 'Accidental deletion of wallets is a common data loss scenario. In most cases, we can successfully recover deleted wallets without any data loss. At Crypto Recovery, we offer a "no data, no charge" guarantee, meaning you only pay if we successfully recover your deleted wallet. If you have accidentally deleted a wallet, it is important to turn off the affected device immediately to avoid permanent data loss.',
    },
    {
        id: '4',
        question: "HARDWARE, SOFTWARE ERRORS, AND VIRUSES",
        size: 5,
        height: '350px',
        answer: 'Hardware and software errors, as well as viruses, can prevent cryptocurrency owners from accessing their coins, even with the correct password. Crypto Recovery specializes in restoring affected files and devices. Our experienced team uses state-of-the-art technology to recover cryptocurrency from failed hard drives, RAID arrays, flash drives, and other devices. If your computer or device is infected with viruses, we can help clear the virus without jeopardizing your cryptocurrency.',
    },
    {
        id: '5',
        question: "UPGRADING OLDER WALLET VERSIONS",
        size: 4,
        height: '350px',
        answer: 'If you are experiencing trouble opening your wallet with the current version of wallet software or are hesitant to upgrade older wallets, Crypto Recovery can help. Our experts can resolve software compatibility issues and provide safe and secure upgrades to ensure you have an up-to-date wallet while maintaining your privacy.',
    },
    {
        id: '6',
        question: "SENT CRYPTOCURRENCY TO AN INVALID ADDRESS",
        size: 3,
        height: '350px',
        answer: 'Accidentally sending cryptocurrency to the wrong address can be a stressful experience. If you have sent funds to an invalid address or the recipient claims they never received the funds, Crypto Recovery can assist you in recovering your cryptocurrency.',
    },
    {
        id: '7',
        question: "LOST FUNDS DUE TO FORKS",
        size: 4,
        height: '300px',
        answer: 'Forks occur when there is a split in the transaction chain due to differing opinions among users about transaction history. If you have a bitcoin balance predating a fork and your funds were affected, Crypto Recovery can help. We work with leading vendors, experts, and employ proven blockchain analytics and investigative techniques to successfully recover your funds.',
    },
    {
        id: '8',
        question: "HACKED EXCHANGE ACCOUNT",
        size: 4,
        height: '300px',
        answer: 'If your cryptocurrency funds have been stolen through a hacked exchange account, Crypto Recovery is here to assist you. We specialize in recovering funds lost due to hacking incidents and have a track record of successfully retrieving stolen assets. Contact us immediately to initiate the recovery process and reclaim your funds.',
    },
    {
        id: '9',
        question: "PHISHING ATTACK VICTIM",
        size: 4,
        height: '300px',
        answer: 'Phishing attacks can lead to the unauthorized access of your cryptocurrency accounts, resulting in significant financial losses. If you have been a victim of a phishing attack and lost access to your funds, Crypto Recovery can help. Our team has extensive experience in dealing with phishing-related cases and can assist you in recovering your stolen funds.',
    },
    {
        id: '10',
        question: "LOST SEED PHRASE OR RECOVERY KEY",
        size: 6,
        height: '250px',
        answer: 'The seed phrase or recovery key is crucial for accessing your cryptocurrency wallet. If you have lost or misplaced your seed phrase or recovery key, it can lead to the permanent loss of your funds. At Crypto Recovery, we specialize in helping individuals recover their lost seed phrases or recovery keys, enabling them to regain access to their wallets and retrieve their cryptocurrencies.',
    },
    {
        id: '11',
        question: "BITCOIN MINING MALWARE",
        size: 6,
        height: '250px',
        answer: 'Bitcoin mining malware can infect your computer or device, utilizing its resources to mine cryptocurrency without your consent. If your device has been infected with mining malware, it can slow down your system and potentially compromise your funds. Crypto Recovery offers malware removal services to rid your device of mining malware and safeguard your cryptocurrencies.',
    },
    {
        id: '12',
        question: "COMPROMISED PRIVATE KEY",
        size: 4,
        height: '300px',
        answer: 'The private key is the gateway to accessing and controlling your cryptocurrency funds. If your private key has been compromised, it puts your funds at risk of theft. Crypto Recovery specializes in assisting individuals who have experienced compromised private keys. Our experts employ advanced techniques to recover compromised private keys and restore the security of your funds.',
    },
    {
        id: '13',
        question: "CRYPTOCURRENCY EXCHANGE SHUTDOWN",
        size: 4,
        height: '300px',
        answer: 'In the event of a cryptocurrency exchange shutdown, users may face difficulties accessing their funds or retrieving their cryptocurrencies. If you have been affected by a cryptocurrency exchange shutdown and are unable to access your funds, Crypto Recovery can assist you in recovering your assets. Our team has the expertise to navigate such situations and retrieve your funds securely.',
    },
    {
        id: '14',
        question: "ERROR IN TRANSACTION",
        size: 4,
        height: '300px',
        answer: 'Mistakes can happen during cryptocurrency transactions, leading to funds being sent to the wrong address or lost in the blockchain. If you have made an error in a cryptocurrency transaction and need assistance in recovering your funds, Crypto Recovery is here to help. We have the necessary tools and expertise to trace and recover lost or misdirected transactions.',
    },
    {
        id: '15',
        question: "COMPROMISED WALLET SECURITY",
        size: 5,
        height: '350px',
        answer: 'If your cryptocurrency wallet security has been compromised, it can put your funds at risk of unauthorized access and theft. At Crypto Recovery, we specialize in assisting individuals who have experienced compromised wallet security. Our team can help you recover your funds and implement enhanced security measures to protect your cryptocurrencies in the future.',
    },
    {
        id: '16',
        question: "LOST CRYPTOCURRENCY KEYS",
        size: 3,
        height: '350px',
        answer: 'Losing your cryptocurrency keys can result in the permanent loss of your funds. If you have lost your cryptocurrency keys and need help in recovering them, Crypto Recovery can assist you. Our experts have the knowledge and tools to recover lost keys and restore access to your funds.',
    },
    {
        id: '17',
        question: "FAILED HARDWARE WALLET",
        size: 4,
        height: '350px',
        answer: 'Hardware wallets provide secure storage for cryptocurrencies, but they can fail due to technical issues or physical damage. If your hardware wallet has failed, and you are unable to access your funds, Crypto Recovery can help. Our team specializes in recovering funds from failed hardware wallets and ensuring the security of your cryptocurrencies.',
    },
    {
        id: '18',
        question: "MULTIPLE WALLET SYNCHRONIZATION",
        size: 6,
        height: '250px',
        answer: 'Synchronizing multiple wallets can be a complex process, and errors can occur, resulting in the loss of access to your funds. If you are facing issues with synchronizing multiple wallets and need assistance in recovering your funds, Crypto Recovery is here to help. Our team can guide you through the synchronization process and ensure the recovery of your cryptocurrencies.',
    },
    {
        id: '19',
        question: "VICTIM OF PONZI SCHEME",
        size: 6,
        height: '250px',
        answer: 'Ponzi schemes targeting cryptocurrency investors can result in significant financial losses. If you have fallen victim to a Ponzi scheme and lost your funds, Crypto Recovery can assist you in recovering your assets. Our team has experience in dealing with Ponzi scheme cases and can help you navigate the recovery process effectively.',
    },
    {
        id: '20',
        question: "LOST CRYPTOCURRENCY INHERITANCE",
        size: 4,
        height: '300px',
        answer: 'Inheriting cryptocurrency assets can be challenging if you do not have the necessary information or access to the funds. If you have lost cryptocurrency assets due to inheritance-related issues, Crypto Recovery can help you recover the lost assets. Our team understands the complexities of cryptocurrency inheritance and can guide you through the recovery process.',
    },
    {
        id: '21',
        question: "UNRECOVERABLE WALLET BACKUP",
        size: 4,
        height: '300px',
        answer: 'Having a backup of your cryptocurrency wallet is crucial, but if the backup is lost or inaccessible, it can result in the permanent loss of your funds. If you are unable to recover your wallet backup and need assistance, Crypto Recovery can help. Our experts have the expertise to explore alternative recovery methods and retrieve your funds.',
    },
    {
        id: '22',
        question: "COMPROMISED EXCHANGE ACCOUNT",
        size: 4,
        height: '300px',
        answer: 'Compromised cryptocurrency exchange accounts can lead to unauthorized access and loss of funds. If your exchange account has been compromised, it is important to act quickly. Crypto Recovery specializes in assisting individuals who have experienced compromised exchange accounts. Our team can help you recover your funds and enhance the security of your accounts.',
    },
];

export const serviceHighlight = [
    {
        id: '0',
        question: "Extensive Wallet and Cryptocurrency Support",
        answer: 'We possess expertise in recovering funds from various types of wallets and cryptocurrencies. Our comprehensive recovery services cover a wide range of wallets and digital assets. Whether you have lost Bitcoin, Ethereum, or any other cryptocurrency, our skilled team has the knowledge and resources to assist you.',
        size: 6,
        height: '220px',
    },
    {
        id: '1',
        question: "Evaluation of Recovery Potential",
        answer: 'While we cannot offer guarantees, we conduct a thorough assessment of each case to determine the likelihood of successful recovery. Our priority is to act in the best interest of our clients, providing honest evaluations of the recovery potential. We assess the unique aspects of your case and guide you on whether proceeding with our services is advisable.',
        size: 6,
        height: '220px',
    },
    {
        id: '2',
        question: "Transparent Pricing Structure",
        answer: 'Pricing is based on factors such as the value of your assets, security complexities, legal considerations, and other relevant factors. We offer flexible pricing options, including contingency-based fees and competitive rates. Our pricing is tailored to your specific circumstances, ensuring transparency and flexibility. Fees typically start at a competitive rate of 15% of the recovered amount.',
        size: 4,
        height: 'auto',
    },
    {
        id: '3',
        question: "Efficient Recovery Timeframe",
        answer: 'We strive to complete the recovery process efficiently and aim to deliver results within a reasonable timeframe. While the exact duration may vary depending on your case details, our dedicated team is committed to achieving tangible outcomes as quickly as possible. Leveraging advanced techniques and resources, we minimize the waiting time for you.',
        size: 4,
        height: 'auto',
    },
    {
        id: '4',
        question: "Secure Cryptocurrency Return",
        answer: 'Upon successful recovery, we ensure the secure return of your cryptocurrency. Through collaboration with trusted law firms, we facilitate the complete return of your valuable investment. Our computer and investigation experts conduct necessary investigations, and the selected law firm handles the return process, granting you full access to your assets.',
        size: 4,
        height: 'auto',
    },
    {
        id: '5',
        question: "Confidentiality and Data Protection",
        answer: 'We prioritize the confidentiality and protection of your sensitive information throughout the recovery process. Our team adheres to strict privacy protocols and employs robust security measures to safeguard your data and personal details. Your privacy is of utmost importance to us, and we take all necessary precautions to maintain the confidentiality of your information.',
        size: 7,
        height: '250px',
    },
    {
        id: '6',
        question: "Dedicated Customer Support",
        answer: 'We provide dedicated customer support to address your concerns and queries throughout the recovery process. Our knowledgeable and responsive team is available to assist you, ensuring a smooth and satisfactory experience. We understand the importance of clear communication and prompt assistance, and we strive to deliver exceptional customer support at every step.',
        size: 5,
        height: '250px',
    },
    {
        id: '7',
        question: "Global Reach and Assistance",
        answer: 'We extend our services globally, offering assistance to victims of cryptocurrency-related fraud worldwide. Regardless of your location, our experienced team is equipped to handle international cases and navigate complex legal and jurisdictional challenges. We are committed to helping individuals across the globe reclaim their lost funds and regain control over their assets.',
        size: 4,
        height: 'auto',
    },
    {
        id: '8',
        question: "Continuous Monitoring and Updates",
        answer: 'Throughout the recovery process, we provide continuous monitoring and regular updates on the progress of your case. Our proactive approach ensures that you are well-informed about the status and developments, giving you peace of mind and confidence in our efforts. We value transparency and strive to keep you updated every step of the way.',
        size: 4,
        height: 'auto',
    },
    {
        id: '9',
        question: "Legal Partnerships and Expertise",
        answer: 'We collaborate with trusted legal partners who specialize in cryptocurrency-related cases. These partnerships enhance our capabilities and provide access to legal expertise specific to the complexities of recovering lost funds. Our combined efforts enable us to navigate legal intricacies and maximize the chances of successful recovery on your behalf.',
        size: 4,
        height: 'auto',
    },
    {
        id: '10',
        question: "Flexible Payment Options",
        answer: 'We offer flexible payment options to accommodate your financial situation. Along with competitive rates, we provide tailored payment plans and options, ensuring that our services are accessible to individuals with varying budgets. We understand the importance of financial flexibility and aim to make our recovery solutions affordable and within reach.',
        size: 6,
        height: 'auto',
    },
    {
        id: '11',
        question: "Wide Range of Recovery Scenarios",
        answer: 'Our expertise extends to a wide range of recovery scenarios, including theft, fraud, technical challenges, and more. Whether you have experienced coin theft, transaction issues, password loss, or other crypto-related problems, our skilled team is equipped to handle diverse recovery situations. We customize our approach to address the specific challenges you face and work towards successful outcomes.',
        size: 6,
        height: 'auto',
    },
]

export const socialMedia = [
    {
        id: 1,
        icon: <BsInstagram color="#fff" size={25} />,
    },
    {
        id: 2,
        icon: <BsFacebook color="#fff" size={25} />,
    },
    {
        id: 2,
        icon: <BsTwitter color="#fff" size={25} />,
    },

]