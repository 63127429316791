import React, { Fragment } from 'react'
import './styles.scss'
import serviceImage from '../../../assets/images/bitcoin.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';


const Introduction2 = ({ children }) => {
    return (
        <Fragment>
            <div className='introduction2Wrapper'>
                <Container>
                    <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div className='introduction2ChildrenContainer'>
                                {children}
                            </div>
                        </Col>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className='introduction2ServiceImageContainer' data-aos="fade-up" data-aos-duration="2000">
                                <img src={serviceImage} alt='serviceImage' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

export default Introduction2