import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackground3.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import StyledAccordion from '../../components/global/accordion'
import { moreData } from '../../data'
import FloatingContainer from '../../components/global/floatingContainer'

const More = () => {
    return (
        <Fragment>

            <Introduction
                height={100}
                title1="DISCOVERING THE WAY TO"
                mainTitle="RECOVER YOUR LOST FUNDS"
                title2="At our firm, we excel in providing solutions for a wide array of circumstances involving lost or inaccessible cryptocurrencies. Our team possesses the expertise to tackle different obstacles related to missing funds and inaccessible digital assets. Common reasons behind cryptocurrency investment losses include:"
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <StyledAccordion
                title='More Q&A'
                data={moreData}
            />

        </Fragment>
    )
}

export default More